export enum AuthRole {
  QRAY_GMP = 'QRAY_GMP',
  QRAY_FSP = 'QRAY_FSP',
  QRAY_MON = 'QRAY_MON',
  QRAY_MANAGER = 'QRAY_GROUPMANAGER',

  PORTAL_EWS = 'PORTAL_EWS',
  PORTAL_COMPANY = 'PORTAL_COMPANY',
  PORTAL_TCFSP = 'PORTAL_TCFSP',
  PORTAL_ADM = 'PORTAL_ADM',
  PORTAL_COORDINATOR = 'PORTAL_COORDINATOR',
  PORTAL_SCAC = 'PORTAL_SCAC',
  PORTAL_EXEMPTION = 'PORTAL_EXEMPTION',
  PORTAL_PARTNER = 'PORTAL_PARTNER',
  PORTAL_STAC = 'PORTAL_STAC',
  PORTAL_SRF = 'PORTAL_SRF',
  PORTAL_SP = 'PORTAL_SP',
  PORTAL_IECAF = 'PORTAL_IECAF',

  RESCO_AUDIT = 'RESCO_AUDIT',

  authenticated = 'authenticated',
  UNRELEASED = 'QRAY_GMP',
}
