import { value } from 'happy-dom/lib/PropertySymbol.d.ts.js'

export const dateFormat = (
  dateString?: string | Date,
  locale = 'en-GB',
  options?: Intl.DateTimeFormatOptions,
) => {
  if (!dateString) return '-'

  const date = new Date(dateString)

  if (isNaN(date.getTime())) return dateString

  return date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...options,
  })
}

export const dateTimeFormat = (dateString?: string | Date) => {
  if (!dateString) return '-'

  const date = new Date(dateString)

  if (isNaN(date.getTime())) return dateString

  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

export const createDateRange = (dateValues: string[] | undefined) => {
  return {
    from: dateValues?.[0] ? new Date(dateValues[0]) : undefined,
    to: dateValues?.[1] ? new Date(dateValues[1]) : undefined,
  }
}

export const toIsoDate = (value: string | Date) => {
  let date = new Date(value)

  // Extract the year, month, and day
  let year = date.getFullYear()
  let month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-based, so add 1
  let day = date.getDate().toString().padStart(2, '0')

  // Format the date as 'yyyy-MM-dd'
  return `${year}-${month}-${day}`
}
