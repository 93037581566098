<script setup lang="ts">
  const props = defineProps<{
    item: {
      auth?: string[]
      name: string
      path: string
      icon?: string
      children?: any[]
      disabled?: boolean
      target?: string
    }
    to?: string
    iconOnly?: boolean
    flip?: boolean
    selected?: boolean
  }>()

  const route = useRoute()
  const { t } = useI18n()

  const isActive = (path: string | object) => {
    if (typeof path === 'object') {
      return route.path.startsWith(path.path)
    }
    return route.path.startsWith(path)
  }

  const authStore = useAuthStore()
</script>

<template>
  <NuxtLink
    v-if="authStore.isInRoles(item.auth?.roles ?? [])"
    :to="props.item.disabled ? '#' : (props.to ?? props.item.path)"
    :target="props.item.target"
  >
    <li
      class="m-2 px-2 py-3 rounded text-base flex gap-2"
      :class="{
        'cursor-pointer': !props.item.disabled,
        'bg-primary text-white': isActive(props.item.path),
        'hover:bg-gray-100 hover:text-black': !isActive(props.item.path),
        'bg-gray-100 text-black': props.selected,
        'font-bold': (item.icon && !to) || isActive(props.item.path),
        ' cursor-not-allowed text-gray-500/60': props.item.disabled,
      }"
    >
      <Icon
        v-if="props.item.icon && props.item.icon?.startsWith('ic:')"
        :name="props.item.icon"
        class="min-w-6 h-6 block"
        :class="{
          'rotate-180': props.flip,
          'text-white': isActive(props.item.path),
          'text-black': !isActive(props.item.path),
        }"
      />
      <img
        :src="`/icons/${props.item.icon}-24px-${isActive(props.item.path) ? 'white' : 'black'}.svg`"
        class="w-6 h-6"
        :class="{ 'rotate-180': props.flip }"
        v-else-if="props.item.icon"
      />
      <div
        class="transition-opacity duration-300 whitespace-nowrap text-ellipsis overflow-hidden ... text-nowrap"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
      >
        {{ t(`nav.menu.${props.item.name}`) }}
      </div>
      <img
        v-if="props.item.children"
        class="transition-opacity"
        :class="iconOnly ? 'opacity-0 invisible' : 'opacity-100'"
        :src="`/icons/chevron-right-24px-${isActive(props.item.path) ? 'white' : 'black'}.svg`"
        width="24"
        height="24"
      />
    </li>
  </NuxtLink>
</template>

<style scoped></style>
