import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import type { CreateMethodRequest, MethodResponse } from '~/clients'

const schema = z.object({
  id: z.number().optional(),
  locationId: z.number(),
  productId: z.number(),
  code: z.string().min(1).max(10),
  name: z.string().min(1),
  expirationDate: z.coerce.date().optional(),
})

export const useManagePortalMyProducts = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateMethodRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<MethodResponse, CreateMethodRequest>(
    type,
    id,
    'portal/my-products',
    defaults,
    'MyProduct',
    mutationOptions,
    schema,
  )
}
