import { ZodError } from 'zod'
import { FetchError } from 'ofetch'
import { ApiException } from '~/clients'
import { AxiosError } from 'axios'
import { H3Error } from 'h3'
import { generateErrorHtmlList } from '~/composables/useMutationWithToast'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    const toast = useToast()
    const route = useRoute()
    const t = useNuxtApp().$i18n.t

    const handleApiException = (errorHandlerError: any, toast: any) => {
      if (errorHandlerError.statusCode === 401 || errorHandlerError.status === 401) {
        showError({
          message: t('error.message.unauthorized'),
          statusCode: 401,
          fatal: true,
        })
        navigateToLogin(route.fullPath)
        return
      }

      if (errorHandlerError.statusCode === 403 || errorHandlerError.status === 403) {
        showError({
          message: t('error.message.forbidden'),
          statusCode: 403,
          fatal: true,
        })
        return
      }

      if (errorHandlerError.statusCode === 404 || errorHandlerError.status === 404) {
        showError({
          message: t('error.message.notFound'),
          statusCode: 404,
          fatal: true,
        })
        return
      }
      if (errorHandlerError.statusCode === 400 || errorHandlerError.status === 400) {
        console.warn('Bad request')
        if (errorHandlerError.data) {
          toast.error(null, null, {
            data: {
              message: t('error.message.badRequest'),
              details: generateErrorHtmlList(errorHandlerError.data),
            },
          })
        }
        return
      } else {
        toast.error(t('error.message.serverError'), undefined, {
          toastId: 'RESERVED_SERVER_ERROR_TOAST',
        })
        return
      }
    }

    if (error?.cause instanceof ZodError) {
      // shouldn't happen
      console.log('Validation error')
      return
    }

    // if (error instanceof H3Error) {
    //   console.log('H3 error', error)
    //   return true
    // }

    // not catching this error, should be handled by vue query instead
    if (error.from === 'api') {
      return
    }

    if (
      error.cause instanceof ApiException ||
      error.cause instanceof AxiosError ||
      // error.cause instanceof FetchError ||
      error.type === 'onRequestError' ||
      error.type === 'onResponseError' ||
      error.statusCode
    ) {
      return handleApiException(error, toast)
    }

    return false
  }
})
